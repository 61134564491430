import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取审核列表
export const ruleList = data => {
  return api({
    url: "/admin/blessing/rule/index",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/blessing/rule/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/blessing/rule/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/blessing/rule/putBack",
    method: "post",
    data
  });
};

// 更新状态
export const updateStatus = data => {
  return api({
    url: "/admin/blessing/rule/updateStatus",
    method: "post",
    data
  });
};

// 祝福规则列表导出
export const documentRuleList = data => {
  return fetchFile({
    url: "/admin/blessing/rule/export",
    method: "post",
    data,
    download: true
  });
};