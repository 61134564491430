<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="规则名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入规则名称"></el-input>
        </el-form-item>
        <el-form-item label="规则类型：" prop="type">
          <el-select v-model="type" placeholder="请选择规则类型">
            <el-option v-for="(item, index) in typeArr" :key="index" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重复推送：" prop="repeat_type">
          <el-select v-model="repeat_type" placeholder="请选择重复推送">
            <el-option v-for="(item, index) in repeatTypeArr" :key="index" :value="item.id"
              :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="推送渠道：" prop="channel">
          <el-select v-model="form.channel" placeholder="请选择推送渠道">
            <el-option v-for="(item, index) in channelArr" :key="index" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="create_start_time"
          :start-time.sync="form.create_start_time" end-prop="create_end_time"
          :end-time.sync="form.create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentRuleList } from "../../api/rule/rule-list";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      typeArr: [
        { id: 0, name: "自定义" },
        { id: 1, name: "入会祝福" },
        { id: 2, name: "入会周年祝福" },
        { id: 3, name: "生日祝福" },
        { id: 4, name: "法定节日祝福" },
        { id: 5, name: "24节气祝福" },
      ],
      repeatTypeArr: [
        { id: 0, name: "从不" },
        { id: 1, name: "每天" },
        { id: 2, name: "每周" },
        { id: 3, name: "每两周" },
        { id: 4, name: "每月" },
        { id: 5, name: "每年" },
      ],
      channelArr: [
        { id: "msg", name: "系统消息" },
        { id: "sms", name: "短信通知" },
        { id: "poster", name: "海报弹窗" },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      documentRuleList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    type: {
      get() {
        return this.form.type == -1 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
    repeat_type: {
      get() {
        return this.form.repeat_type == -1 ? "" : this.form.repeat_type;
      },
      set(val) {
        this.form.repeat_type = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>